export default function Ranks() {
  return [
    {
      ranking: "Rookie Capstar I (RC 1)",
      range: [5, 50],
    },
    {
      ranking: "Rookie Capstar II (RC 2)",
      range: [51, 100],
    },
    {
      ranking: "Rookie Capstar III (RC 3)",
      range: [101, 150],
    },
    {
      ranking: "Junior Capstar I",
      range: [151, 250],
    },
    {
      ranking: "Junior Capstar II",
      range: [251, 350],
    },
    {
      ranking: "Junior Capstar III",
      range: [351, 450],
    },
    {
      ranking: "Senior Capstar I",
      range: [451, 750],
    },
    {
      ranking: "Senior Capstar II",
      range: [751, 1050],
    },
    {
      ranking: "Senior Capstar III",
      range: [1051, 1350],
    },
    {
      ranking: "Elite Capstar I",
      range: [1351, 1950],
    },
    {
      ranking: "Elite Capstar II",
      range: [1951, 2550],
    },
    {
      ranking: "Elite Capstar III",
      range: [2551, 3150],
    },
    {
      ranking: "Master Capstar I",
      range: [3151, 4450],
    },
    {
      ranking: "Master Capstar II",
      range: [4451, 5750],
    },
    {
      ranking: "Master Capstar III",
      range: [5751, 7050],
    },
    {
      ranking: "Legendary Capstar I",
      range: [7051, 10950],
    },
    {
      ranking: "Legendary Capstar II",
      range: [10951, 14950],
    },
    {
      ranking: "Legendary Capstar III",
      range: [14951, 18950],
    },
    {
      ranking: "Supreme Capstar I",
      range: [18951, 25950],
    },
    {
      ranking: "Supreme Capstar II",
      range: [25951, 32950],
    },
    {
      ranking: "Supreme Capstar III",
      range: [32951, 39950],
    },
    {
      ranking: "Ultimate Capstar I",
      range: [39951, 52950],
    },
    {
      ranking: "Ultimate Capstar II",
      range: [52951, 65950],
    },
    {
      ranking: "Ultimate Capstar III",
      range: [65951, 78950],
    },
    {
      ranking: "Grandmaster Capstar I",
      range: [78951, 105950],
    },
    {
      ranking: "Grandmaster Capstar II",
      range: [105951, 132950],
    },
    {
      ranking: "Grandmaster Capstar III",
      range: [132951, 159950],
    },
    {
      ranking: "Apex Capstar I",
      range: [159951, 213950],
    },
    {
      ranking: "Apex Capstar II",
      range: [213951, 267950],
    },
    {
      ranking: "Apex Capstar III",
      range: [267951, 318950],
    },
    {
      ranking: "Capstar Marshal",
      range: [318951, 4000000],
    },
  ];
}
