import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { formstyles } from "../../helpers/Styles";
import {
  useTransactionPin,
  useUserProfile,
  useUserWalletData,
} from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";

const TransactionPin = () => {
  useUserWalletData();
  useUserProfile();
  const navigate = useNavigate();
  const {
    createTransactionPin,
    changeTransactionPin,
    resetTransactionPin,
    serverError,
    showSuccessPopUp,
  } = useTransactionPin();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const { state, dispatch } = useContext(Context);
  const { userWalletData, userProfile, showPinOtpPopup } = state;
  const pinValue = watch("pin");
  const otpValue = watch("otp");

  const otpSubmit = async () => {
    const payload = {
      pin: pinValue,
      otp: otpValue,
    };
    await resetTransactionPin(payload);
  };

  const onSubmit = async (formData) => {
    userWalletData?.is_set_pin
      ? await changeTransactionPin(formData)
      : await createTransactionPin(formData);
  };

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

  const handleInputChange = (e, value, index) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    // Combine OTP values and set it to "otp"
    const combinedOtp = newOtpInputs.join("");
    setValue("otp", combinedOtp);

    // Automatically move to the next input
    if (value.length === 1) {
      const nextInput = e.target.nextElementSibling;
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value) {
      const previousInput = e.target.previousElementSibling;
      if (previousInput) previousInput.focus();
    }
  };

  const OtpPopUp = () => {
    const [countDown, setCountDown] = useState(true);
    const [timeLeft, setTimeLeft] = useState(30);

    useEffect(() => {
      if (countDown && timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else if (timeLeft === 0) {
        setCountDown(false);
        setTimeLeft(0);
      }
    }, [countDown, timeLeft]);

    // Trigger to restart the countdown (if needed)
    const restartCountdown = () => {
      setCountDown(true);
      setTimeLeft(30);
    };
    return (
      <form
        onSubmit={handleSubmit(otpSubmit)}
        className="w-full h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-end justify-center z-50 text-[#592E6D] "
      >
        {/* transition-all duration-500 transform animate-slide-up */}
        <div className="relative rounded-t-3xl flex flex-col items-center justify-center bg-white p-[30px] pb-[200px] rounded-md shadow-lg">
          <div className="flex justify-end w-full">
            <img
              src="/assets/images/close.png"
              alt="close"
              onClick={() =>
                dispatch({
                  type: "SHOW_PIN_OTP_POPUP",
                  payload: false,
                })
              }
            />
          </div>
          <p className="text-center">OTP Sent!</p>
          <p className="text-center pt-[30px]">
            An OTP has been sent to your registered email address{" "}
            <span>
              {userProfile?.user?.email &&
                (() => {
                  const email = userProfile.user.email;
                  const [localPart, domain] = email.split("@");
                  const maskedLocal = localPart.substring(0, 6) + "***";
                  return `${maskedLocal}@${domain}`;
                })()}{" "}
            </span>
            to complete this action.
          </p>

          <div
            className="flex gap-[20px] pt-[30px]"
            onSubmit={handleSubmit(otpSubmit)}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                id={`otp-${index}`}
                type="text"
                maxLength="1"
                value={otpInputs[index]}
                className="w-10 h-10 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#592E6D] text-lg"
                onChange={(e) => handleInputChange(e, e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                required
              />
            ))}
          </div>

          <div className="flex justify-end w-full items-center gap-[6px] py-[30px]">
            {countDown ? (
              <span className="opacity-[50%]">Resend OTP</span>
            ) : (
              <button
                className="underline"
                onClick={() => {
                  onSubmit({ pin: pinValue });
                }}
              >
                Resend OTP
              </button>
            )}

            <span>{timeLeft}s</span>
            <img
              src="/assets/images/otpTimer.png"
              alt="timer"
              className="w-[10px] h-[12px]"
            />
          </div>

          <p className="w-full text-left text-[0.9rem] text-[#592E6D] pb-[30px]">
            {serverError && (
              <Typography variant="caption" color="error">
                {serverError}
              </Typography>
            )}
          </p>

          <button
            className="text-[12px] rounded-lg shadow-lg bg-[#592E6D] text-white w-full px-[10px] py-[10px] cursor-pointer"
            type="submit"
          >
            Verify OTP
          </button>
        </div>
      </form>
    );
  };

  const SuccessPopUp = () => {
    const navigate = useNavigate();
    return (
      <div className="w-full h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 text-[#592E6D]">
        <div className="relative flex flex-col items-center justify-center bg-white p-[30px] rounded-md shadow-lg">
          <div className="flex justify-end w-full">
            <img
              src="/assets/images/close.png"
              alt="close"
              onClick={() => {
                dispatch({
                  type: "SHOW_PIN_OTP_POPUP",
                  payload: false,
                });
                navigate("/settings");
              }}
            />
          </div>
          <p className="text-center ">Success </p>
          <p className="text-center text-[0.9rem] py-[15px]">
            You have successfully{" "}
            {userWalletData?.is_set_pin ? "changed" : "created"} your pin!
          </p>
        </div>
      </div>
    );
  };

  return (
    <NavLayout
      title="TransactionPin"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              {userWalletData?.is_set_pin
                ? "Change Transaction Pin"
                : "Create Transaction Pin"}
            </Typography>
          </Box>

          <Box width={"100%"} px={"20px"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "130px",
              }}
            ></Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="text-[#592E6D]">
                {userWalletData?.is_set_pin
                  ? "Change Transaction Pin"
                  : "Create Transaction Pin"}
              </span>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "7px 0 7px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("pin", {
                    required: "Pin is required",
                    minLength: {
                      value: 4,
                      message: "Pin must be atleast 4 characters",
                    },
                    maxLength: {
                      value: 4,
                      message: "Pin cannot be more than 4 characters",
                    },
                  })}
                  error={!!errors.pin}
                  helperText={errors.pin?.message}
                />
              </Box>

              <span className="text-[#592E6D]">Confirm transaction pin</span>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "7px 0 7px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("confirmPin", {
                    required: "Confirm Pin is required",
                    validate: (value) =>
                      value === pinValue || "Pins do not match",
                  })}
                  error={!!errors.confirmPin}
                  helperText={errors.confirmPin?.message}
                />
              </Box>

              <Box textAlign="left" mb="10px">
                {serverError && (
                  <Typography variant="caption" color="error">
                    {serverError}
                  </Typography>
                )}
              </Box>

              <Box margin="100px auto" width="100%">
                <button
                  type="submit"
                  className=" rounded-[5px] bg-[#592E6D] w-full h-[56px] text-white text-[0.75rem] font-SF font-[600] "
                >
                  {userWalletData?.is_set_pin ? "CHANGE PIN" : "CREATE PIN"}
                </button>
              </Box>
            </form>
          </Box>
          {showPinOtpPopup && <OtpPopUp />}
          {showSuccessPopUp && <SuccessPopUp />}
        </Box>
      }
    />
  );
};

export default TransactionPin;
