export const TransactionSkeleton = () => {
  return (
    <div className="flex items-center justify-between py-[10px] border-b border-[#FF1CF7] border-opacity-50">
      <div className="flex flex-col gap-[10px]">
        <div className="animate-pulse h-[10px] w-[80px] opacity-75 rounded bg-[#592E6D]"></div>
        <div className="animate-pulse h-[10px] w-[60px] opacity-75 rounded bg-[#592E6D]"></div>
      </div>
      <div className="animate-pulse h-[10px] w-[50px] opacity-75 rounded bg-[#592E6D]"></div>
      <div className="animate-pulse h-[10px] w-[50px] opacity-75 rounded bg-[#592E6D]"></div>
    </div>
  );
};

export const SingleLineSkeleton = ({ extraClass }) => (
  <div className={`animate-pulse opacity-75 ${extraClass}`}></div>
);

export const ReferralSkeleton = () => (
  <div className="flex flex-col items-center justify-center gap-y-[10px] p-[15px_10px] border-b-[0.2px] border-brandPink">
    <div className="h-[6px] w-[40%] rounded bg-brandPurp opacity-50 animate-pulse"></div>
    <div className="h-[6px] w-[60%] rounded bg-brandPurp opacity-50 animate-pulse"></div>
    <div className="h-[6px] w-[80%] rounded bg-brandPurp opacity-50 animate-pulse"></div>
    <div className="w-full p-[8px_0px] rounded-[5px] bg-brandPurp flex items-center justify-center">
      <div className=" h-[6px] w-[40px] rounded bg-white opacity-75 animate-pulse"></div>
    </div>
  </div>
);
